@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css');

html {
    font-family: "Inter";
    font-size: 4dvh;
}

body {
    margin: 0;
    font-family: "Inter";
    font-weight: 200;
}

button {
    box-shadow: rgba(0, 0, 0, 0.24) 0 1px 2px;
    background: rgba(255, 196, 24, 0.8117647059);
    border: 1px solid #7b7b7b;
    align-items: center;
    display: flex;
    width: 180px;
    transform: rotate(-2deg);
    height: 6.5dvh;
    margin: 4dvh auto 0 auto;
    transition: all 0.1s ease-out;
    font-size: max(3dvh, 14px);
    padding-right: 10px;
    color: #202020;
    opacity: 1;
    font-family: "Lato";
    font-weight: 400;
    padding-left: 15px;
    opacity: 0.85;

    .icon {
        font-size: 5dvh;
        color: #533a5b;
        position: absolute;
        right: 15px;
    }

    &:hover {
        opacity: 0.95;
        cursor: pointer;
        transform: rotate(-2deg) scale(1.025);
    }
}

.main {
    background-image: linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    overflow: hidden;

    .container {
        display: flex;
        height: 80dvh;
        margin: 0;

        .column {
            flex: 1; /* Make each column take up an equal share of the container's width */
            display: flex;
            align-items: center; // vertically
            justify-content: center;  /* Optional: Center horizontally */
            height: 80dvh;
        }
    }
}

.game {
    margin: 0 auto;
    text-align: center;
    width: 56vw;

    #question {
        height: 10dvh;
        font-size: 9dvh;
    }

    #answer {
        font-size: max(5dvh, 18px);
        text-align: center;
        height: 8dvh;
        display: block;
        margin: 2dvh auto 0 auto;
        line-height: 8dvh;
        border: 2px solid #7b7b7b;
        border-radius: 8px;
        background-color: white;
        transition: background-color 500ms ease-in-out;

        &.good {
            background-color: #4CAF50;
        }

        &.bad {
            background-color: #f14848;
        }
    }

    button {
        &.again {
            margin-top: 6dvh;
            padding: 4dvh 1dvh;
            width: 80%;
            justify-content: center;
        }
    }

    .depletion {
        --speed: 5s;
        padding: 0.6dvh;
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.35);
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
        width: 52vw;
        height: 2.4dvh;
        margin: 4dvh auto 4dvh auto;

        .depletion-bar {
            height: 100%;
            border-radius: 8px;
            margin: 0;
            width: 52vw;
            transition-property: background-color;
            vertical-align: middle;
        }

        &.depletion-start {
            background: rgba(0, 0, 0, 0.25);

            .depletion-bar {
                width: 0px;
                background-color: #EF476F;
                transition: width var(--speed) linear, background-color 0.4s linear;
            }
        }
    }

    .number-pad {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, 1fr);
        gap: 0;
        grid-auto-flow: row;
        grid-template-areas:
            "seven eight nine back"
            "four five six ."
            "one two three ."
            ". z enter enter";
        font-size: 4dvh;
        height: 34dvh;
        margin: 0 auto;
        cursor: default;
        -webkit-user-select: none;
        user-select: none;

        div {
            border-radius: 8px;
            background-image: linear-gradient(to top, #fdde89 0%, #f7d196 100%);
            box-shadow: rgba(0, 0, 0, 0.24) 0 1px 2px;
            border: 2px solid #7b7b7b;
            margin: 2px;
            align-items: center;
            display: flex;
            justify-content: center;
        }

        .enter {
            grid-area: enter;
            min-width: unset;
        }
        .back { grid-area: back; }
        .z { grid-area: z; }
        .one { grid-area: one; }
        .two { grid-area: two; }
        .three { grid-area: three; }
        .four { grid-area: four; }
        .five { grid-area: five; }
        .six { grid-area: six; }
        .seven { grid-area: seven; }
        .eight { grid-area: eight; }
        .nine { grid-area: nine; }
    }

    img.sad {
        width: 80%;
        background: #32414b;
        padding: 10px;
        border-radius: 4vw;
        margin-top: 6dvh;
    }

    .sum-full {
        background: rgb(255 255 255 / 25%);
        border-radius: 2dvh;
        padding: 10px;
        font-family: monospace;
        margin: 2dvh auto 0 auto;
        width: 80%;

        div {
            font-size: 5dvh;
            text-align: right;
            padding-right: 40px;
        }

        .factor2 {
            border-bottom: 0.5dvh solid #ee594e;
            position: relative;

            &:after {
                position: absolute;
                content: "x";
                right: 0;
                color: #ee594e;
                font-weight: bold;
            }
        }

        .sum-answer {

        }

        &.addition {
            .factor2:after {
                content: "+";
            }
        }

        &.subtraction {
            .factor2:after {
                content: "-";
            }
        }
    }
}

#reward-modal {
    z-index: 2;
    position: absolute;
    width: 100vw;
    background: rgba(0, 0, 0, 0.55);
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80dvh;

    img {
        max-width: 92vw;
        max-height: 60dvh;
        border: 8px solid #FFEB3B;
        border-radius: 8px;
    }
}

// repositioned component
.lives {
    display: inline-block;
    vertical-align: middle;
    margin-top: 0.5dvh;

    .live {
        font-size: 4dvh;
        color: #e4155b;
        background: rgba(255, 255, 255, 0.2392156863);
        width: 6dvh;
        height: 5.5dvh;
        border-radius: 8px;
        text-align: center;
        display: inline-block;
        border: 1px solid #767676;
        margin: 0 5px 0 0;
        -webkit-user-select: none;
        user-select: none;

        span {
            vertical-align: middle;
        }
    }
}

#menu {
    box-shadow: rgba(0, 0, 0, 0.24) 4px 5px 5px;
    background: white;
    position: absolute;
    padding: 5px 10px;
    border-radius: 9px;
    font-family: monospace;
    width: 88vw;
    top: 2dvh;
    right: 4vw;
    font-size: 16px;
    z-index: 4;

    .row {
        padding: 4px 0px 1px 0px;
        margin: 5px 0px;
        border: 1px solid #939393;
        border-radius: 2px;
        background: #f3f3f3;

        &:last-child {
            border-bottom: 1px solid #939393;
        }

        div {
            display: block;
            text-align: center;
            margin-bottom: 10px;
        }

        .label {
            margin-bottom: 6px;
            width: inherit;
        }

        input, select {
            border: 1px solid black;
            font-family: monospace;
            width: 70px;
            padding: 2px 4px;
            font-size: 16px;
            background: white;
        }

        select {
            width: 200px;
        }

        ul.picker {
            list-style-type: none;
            margin: 0;
            padding: 0;
            border: 1px solid black;
            display: inline-flex;

            li {
                float: left;
                border-right: 1px solid black;
                padding: 2px 8px;
                cursor: pointer;
                background-color: white;

                &:last-child {
                    border-right: 0;
                }

                &.selected {
                    background-color: #e9a4e9;
                }
            }
        }

        a.author {
            color: #423d4f;
            padding: 0px 1vw 1px 1vw;
            text-decoration: none;
            border-bottom: 2px solid #FF9800;
            cursor: pointer;
        }
    }
}

.mobile {
    #rewards {
        position: absolute;
        z-index: 3;
        bottom: 7dvh;
        width: 100vw;
        background: rgb(255 41 25 / 7%);
        display: flex;
        align-items: center;
        height: 13dvh;
        box-shadow: inset 0 0.25vw 0.5vw rgba(0, 0, 0, 0.25), 0 0.25vw rgba(255, 255, 255, 0.08);
        overflow-x: auto;
        overflow-y: hidden;

        .reward-image-wrapper {
            max-width: 10dvh;
            height: 12dvh;
            margin: 1.5dvh 0 0 10px;

            img {
                border: 2px solid #FFEB3B;
                border-radius: 8px;
                cursor: pointer;
                max-width: 10dvh;
                max-height: 10dvh;
            }
        }

        &.reward-shown {
            background: rgba(0, 0, 0, 0.55);

            img {
                opacity: 0.56;
            }
        }
    }
}

.status-bar {
    background: rgba(255, 255, 255, 0.2);
    width: calc(100vw - 10px);
    height: 7dvh;
    bottom: 0;
    left: 0;
    padding: 0px 5px;
    position: fixed;

    .correct {
        border: 1px solid #494949;
        border-radius: 8px;
        min-width: 6dvh;
        height: 5.5dvh;
        background: rgb(255 223 59 / 64%);
        display: inline-block;
        font-size: 3dvh;
        text-align: center;
        margin-right: 5vw;
        vertical-align: middle;
        margin-top: 0.5dvh;

        p {
            transform: translateY(-50%);
        }
    }

    .right {
        position: absolute;
        right: 1vw;
        top: 1dvh;

        .settings-button {
            width: 5dvh;
            height: 5dvh;
            border-radius: 1vw;
            cursor: pointer;
            z-index: 3;
            background-color: rgba(255, 255, 255, 0.25);
            border: 1px solid #767676;

            &:hover, &:focus {
                background: rgb(255 255 255 / 38%);
            }
        }
    }

    &.reward-shown {
        background: rgba(0, 0, 0, 0.55);
    }
}

.konijnen {
    &.main {
        background-image: linear-gradient(to top, #7780b3 0%, #d2d8e9 100%);

        ul.picker li.selected {
            background-color: #b9e4ff !important;;
        }

        .game {
            button {
                background-color: #4bdcffcf;
            }

            .depletion-start .depletion-bar {
                background-color: #F44336;
            }
        }

        .live {
            color: #e70000 !important
        }
    }
}

.dinos {
    &.main {
        background-image: linear-gradient(to top, #cdf7bf 0%, #7aa27b 100%);

        ul.picker li.selected {
            background-color: #b4fe5e !important;
        }

        .game {
            button {
                background-color: rgb(255 214 24 / 81%);
            }

            .depletion-start .depletion-bar {
                background-color: #f1ae00;
            }
        }

        .live {
            color: #F44336 !important;
        }
    }
}

@media (min-aspect-ratio: 1/5) {
    .mobile .game {
        width: 90vw;

        .depletion, .depletion-bar {
            width: 88vw;
        }
    }
}

@media (min-aspect-ratio: 1/2) {
    .mobile .game {
        width: 68vw;

        .depletion, .depletion-bar {
            width: 66vw;
        }
    }
}

@media (min-aspect-ratio: 3/5) {
    .mobile .game {
        width: 56vw;

        .depletion, .depletion-bar {
            width: 55vw;
        }
    }
}


@media (min-aspect-ratio: 4/5) {
    .mobile .game {
        width: 38vw;

        .depletion, .depletion-bar {
            width: 36vw;
        }
    }
}

@media (min-aspect-ratio: 13/10) {
    .mobile .game {
        width: 31vw;

        .depletion, .depletion-bar {
            width: 30vw;
        }
    }
}

@media (min-aspect-ratio: 8/5) {
    .mobile .game {
        width: 26vw;

        .depletion, .depletion-bar {
            width: 25vw;
        }
    }
}

@media (min-aspect-ratio: 2/1) {
    .mobile .game {
        width: 20vw;

        .depletion, .depletion-bar {
            width: 19vw;
        }
    }
}

@media (min-aspect-ratio: 3/1) {
    .mobile .game {
        width: 13vw;

        .depletion, .depletion-bar {
            width: 13vw;
        }
    }
}

.main.desktop {
    .container {
        height: 100dvh;
        display: flex;

        .column {
            height: 100dvh;
            flex: 4;

            &:first-child {
                flex: 7;
                min-width: 435px;
            }

            &:last-child {
                justify-content: left;
                flex: 7;
            }
        }
    }

    // left column
    #reward {
        text-align: center;

        img {
            max-width: min(33dvw, 460px);
            max-height: min(71dvh, 460px);
            border: 6px solid #FFEB3B;
            border-radius: 8px;
        }
    }

    // middle column
    .game {
        margin: 0px auto;
        text-align: center;
        width: 17vw;
        font-size: unset;

        #question {
            font-size: 10dvh;
        }

        #answer {
            width: 100%;
            margin-top: 3dvh;
        }

        .depletion {
            width: 100%;

            .depletion-bar {
                width: 100%;
            }

            &.depletion-start {
                .depletion-bar {
                    width: 0px;
                }
            }
        }

        button {
            width: 80%;
        }

        img.sad {
            margin-top: 0dvh;
        }

        #points {
            font-size: max(2.5dvh, 12px);
            margin: 10dvh auto 0 auto;
            font-family: "Lato";
            font-weight: 400;

            #correct {
                border: 1px solid #3f3f3f;
                border-radius: 64px;
                height: 5dvh;
                width: 5dvh;
                line-height: 5dvh;
                background: #FFC107;
                display: inline-block;
            }
        }

        .lives {
            margin-top: 2dvh;
        }
    }

    // right column
    #rewards {
        text-align: left;
        height: 71dvh;
        width: 37dvw;
        //background: rgba(255, 41, 25, 0.07);
        //box-shadow: inset 0 0.25vw 0.5vw rgba(0, 0, 0, 0.25), 0 0.25vw rgba(255, 255, 255, 0.08);
        //border-radius: 6px;
        padding: 2dvh 2dvh 0 0;
        margin-left: 1dvw;

        .reward-image-wrapper {
            max-width: 21%;
            height: 21%;
            margin: 0px 0px 2dvh 2vh;
            display: inline-block;
            vertical-align: top;

            img {
                border: 2px solid #FFEB3B;
                border-radius: 8px;
                cursor: pointer;
                max-width: 100%;
                max-height: 100%;
            }
        }
    }

    // absolute
    .settings-button {
        width: 4dvh;
        height: 4dvh;
        padding: 4px;
        background: rgba(255, 255, 255, 0.25);
        border-radius: 4px;
        position: absolute;
        top: 1dvh;
        right: 1dvh;
        cursor: pointer;
        z-index: 3;

        &:hover, &:focus {
            background: rgb(255 255 255 / 38%);
        }
    }

    @media (min-aspect-ratio: 11/5) {
        .game {
            width: 15vw;
        }
    }

    @media (min-aspect-ratio: 13/5) {
        .game {
            width: 13vw;
        }
    }
}

@mixin menuDesktop() {
    width: 390px;
    top: 60px;
    right: 1dvh;
    background: rgba(255, 255, 255, 0.96);
    padding: 11px 24px;
    border-radius: 2px;
    font-size: 18px;

    .row {
        border-radius: 0;
        margin: 0;
        border: 0;
        border-bottom: 1px solid black;
        background: transparent;
        padding: 5px 0px;

        &:last-child {
            border-bottom: 0;
        }

        div {
            display: inline-block;
            text-align: left;
            vertical-align: middle;
            margin: 0;
            padding: 3px 0px;
        }

        .label {
            width: 150px;
        }

        input, select {
            width: 170px;
            font-size: inherit;
        }

        a.author {
            color: #423d4f;
            padding: 0px 2px 1px 2px;
            border-bottom: 2px solid transparent;
            text-decoration: none;

            &:hover {
                border-bottom: 2px solid #FF9800;
                cursor: pointer;
            }
        }
    }
}

#menu.desktop {
    @include menuDesktop;
}

#menu {
    @media (max-height: 440px) {
        @include menuDesktop;

        width: unset;
        top: unset;
        bottom: 8dvh;
        right: 1dvh;
        background: rgba(255, 255, 255, 0.96);
        padding: 11px 24px;
        border-radius: 2px;
        font-size: 4dvh;
        line-height: 4dvh;

        .row {
            padding: 0.2dvh 0;

            @media (max-width: 440px) {
                div {
                    display: block;
                }

                .label {
                    margin: 0;
                }
            }
        }
    }
}